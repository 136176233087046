import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {X, Download, Check} from 'lucide-react';

const InstallPrompt = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [installed, setInstalled] = useState(false);
    const [dissmissed, setDissmissed] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        }
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', () => {
            setInstalled(true);
            setDeferredPrompt(null);
        });
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        }
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) {
            return;
        }
        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;

        if (choiceResult.outcome === 'accepted') {
            setDeferredPrompt(true);
        } else {
            setDissmissed(true);
        }

        setDeferredPrompt(null);
    }

    const handleDismiss = () => {
        setDissmissed(true);
    }

    if (installed || !deferredPrompt || dissmissed) {
        return null;
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{y: -100, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                exit={{y: -100, opacity: 0}}
                transition={{type: "spring", stiffness: 300, damping: 30}}
                className="fixed top-0 left-0 right-0 z-50 mx-auto max-w-md px-4"
                style={{zIndex: 100}}
            >
                <div
                    className="mt-4 rounded-lg bg-white dark:bg-gray-800 shadow-xl border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="relative p-4 flex items-center">
                        <div className="mr-4 bg-blue-100 dark:bg-blue-900 p-2 rounded-full">
                            <Download size={24} className="text-blue-600 dark:text-blue-300"/>
                        </div>
                        <div className="flex-1">
                            <h3 className="font-medium text-gray-900 dark:text-white">Instalar aplicación</h3>
                            <p className="text-sm text-gray-500 dark:text-gray-400">Instala esta app para un acceso más
                                rápido</p>
                        </div>
                        <button
                            onClick={handleDismiss}
                            className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                            aria-label="Cerrar"
                        >
                            <X size={20} className="text-gray-500 dark:text-gray-400"/>
                        </button>
                    </div>
                    <div className="px-4 pb-4 flex justify-end space-x-2">
                        <button
                            onClick={handleDismiss}
                            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
                        >
                            Ahora no
                        </button>
                        <button
                            onClick={handleInstallClick}
                            className="px-4 py-2 text-sm font-medium bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center"
                        >
                            <span>Instalar</span>
                            <Check size={16} className="ml-1"/>
                        </button>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

export default InstallPrompt;
