import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Flex,
    Input,
    Button,
    Text,
    Image,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    Container,
    Badge,
    useBreakpointValue
} from '@chakra-ui/react';
import {
    FaSearch,
    FaChevronRight
} from 'react-icons/fa';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../../assets/logo.png';

const Card = ({ openTime, discount, coverPhoto, category, name, description, id, descriptionStyle }) => {
    const handleClick = () => {
        window.location.href = `/commerces/${id}`
    }
    return (
        <Box bg="gray.800" borderRadius="lg" overflow="hidden" mb={4}>
            <Box position="relative">
                <Image
                    src={coverPhoto?.url || "https://placehold.co/400x200"}
                    alt={`Image of ${name}`}
                    w="full"
                    h="48"
                    objectFit="cover"
                />
                <Badge
                    position="absolute"
                    top="2"
                    left="2"
                    bg="red.600"
                    color="white"
                    px="3"
                    py="1"
                    borderRadius="full"
                >
                    {openTime}
                </Badge>
                <Badge
                    position="absolute"
                    top="2"
                    right="2"
                    bg="green.600"
                    color="white"
                    px="3"
                    py="1"
                    borderRadius="full"
                >
                    {discount}
                </Badge>
            </Box>
            <Box p={4}>
                <Flex align="center" mb={2}>
                    <Image
                        src={coverPhoto?.url || "https://placehold.co/40"}
                        alt={`Logo of ${name}`}
                        w="10"
                        h="10"
                        borderRadius="full"
                        mr="2"
                    />
                    <Box>
                        <Text color="red.600" fontWeight="bold">
                            {category?.name || ""}
                        </Text>
                        <Text color="white" fontWeight="medium">
                            {name}
                        </Text>
                    </Box>
                </Flex>
                <Text color="gray.400" fontSize="sm" mb={4} style={descriptionStyle}>
                    {description}
                </Text>
                <Button
                    bg="red.600"
                    color="white"
                    w="full"
                    py={2}
                    borderRadius="full"
                    rightIcon={<FaChevronRight />}
                    onClick={handleClick}
                >
                    Ver más información
                </Button>
            </Box>
        </Box>
    );
};

const BarList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [activeCategory, setActiveCategory] = useState('Todos');
    const [bars, setBars] = useState([]);
    const [categories, setCategories] = useState(['Todos']);
    const api = process.env.REACT_APP_BACKEND_BASE_URL;

    useEffect(() => {
        // Fetch bars from backend
        const fetchBars = async () => {
            try {
                // For demonstration, using your sample data


                // In a real implementation, you would fetch from the API like this:
                // const response = await fetch(`${api}/bars`);
                // const data = await response.json();
                const response = await axios.get(`${api}/companies`);
                const categories = await axios.get(`${api}/categories`);
                setCategories(categories.data);

                setBars(response.data);

                // Extract unique categories
            } catch (error) {
                console.error("Error fetching bars:", error);
            }
        };

        fetchBars();
    }, [api]);

    // Get current day and format business hours for display
    const getCurrentDayOpenTime = (businessHours) => {
        if (!businessHours) return "Horario no disponible";

        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = days[new Date().getDay()];

        if (businessHours[today]) {
            return `Abre ${businessHours[today].open}`;
        }

        return "Horario no disponible";
    };

    // Filter by category and search term
    const filteredBars = bars.filter(bar =>
        (activeCategory === 'Todos' || (bar.category?.name && bar.category.name.toLowerCase() === activeCategory.toLowerCase())) &&
        (bar.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            bar.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (bar.category?.name && bar.category.name.toLowerCase().includes(searchTerm.toLowerCase())))
    );

    // Columns responsive: 1 on mobile, 2 on desktop
    const columns = useBreakpointValue({ base: 1, md: 2 });

    return (
        <Box bg="gray.900" color="white" fontFamily="'Roboto', sans-serif">
            <Container maxW={{ base: "100%", md: "90%", lg: "80%" }} p={4}>
                {/* Header */}
                <Flex align="center" justify="space-between" mb={4}>
                    {/* <Image
                        src={logo}
                        alt="Logo"
                        w="20"
                        h="20"
                        borderRadius="full"
                    /> */}

                    
                    <Box position="relative">
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <FaSearch color="gray.400" />
                            </InputLeftElement>
                            <Input
                                bg="gray.800"
                                color="white"
                                placeholder="¿Gastrobar?"
                                borderRadius="full"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                _focus={{ outline: "none" }}
                            />
                        </InputGroup>
                    </Box>
                </Flex>

                {/* Navigation */}
                <Flex
                    space={4}
                    mb={4}
                    overflowX={{ base: "auto", md: "visible" }}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {categories.length > 0 && categories.map((category) => (
                        <Button
                            key={category.id}
                            bg={activeCategory === category ? "red.600" : "transparent"}
                            color={activeCategory === category ? "white" : "gray.400"}
                            px={4}
                            py={2}
                            borderRadius="full"
                            mr={4}
                            onClick={() => setActiveCategory(category.name)}
                            _hover={{ bg: activeCategory === category ? "red.700" : "gray.700" }}
                            minW="fit-content"
                        >
                            {category.name}
                        </Button>
                    ))}
                </Flex>

                {/* Cards */}
                <SimpleGrid columns={columns} spacing={4}>
                    {filteredBars.map(bar => (
                        <Card
                            key={bar.id}
                            openTime={getCurrentDayOpenTime(bar.businessHours)}
                            discount={bar.discount || "0% OFF"}
                            coverPhoto={bar.coverPhoto}
                            category={bar.category}
                            name={bar.name}
                            description={bar.description}
                            id={bar.id}
                            descriptionStyle={{ whiteSpace: 'pre-line' }}
                        />
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default BarList;

// const Header = () => {
//     const useScrollDirection = () => {
//         const [scrollDirection, setScrollDirection] = useState("up");
//         const [isAtTop, setIsAtTop] = useState(true);
//         const [lastScrollY, setLastScrollY] = useState(0);

//         useEffect(() => {
//             const updateScrollDirection = () => {
//                 const scrollY = window.scrollY;
//                 setIsAtTop(scrollY < 10);

//                 const direction = scrollY > lastScrollY ? "down" : "up";
//                 if (direction !== scrollDirection &&
//                     (Math.abs(scrollY - lastScrollY) > 10)) {
//                     setScrollDirection(direction);
//                 }
//                 setLastScrollY(scrollY > 0 ? scrollY : 0);
//             };

//             window.addEventListener("scroll", updateScrollDirection);
//             return () => window.removeEventListener("scroll", updateScrollDirection);
//         }, [scrollDirection, lastScrollY]);

//         return { scrollDirection, isAtTop };
//     };
//     const searchContainerRef = useRef(null);
//     const { scrollDirection, isAtTop } = useScrollDirection();
//     const shouldShowSearch = scrollDirection === "up" || isAtTop;
//     return (
//         <AnimatePresence>
//             {shouldShowSearch && (
//                 <motion.div
//                     ref={searchContainerRef}
//                     initial={{ y: -100, opacity: 0 }}
//                     animate={{ y: 0, opacity: 1 }}
//                     exit={{ y: -100, opacity: 0 }}
//                     transition={{ duration: 0.3, ease: "easeInOut" }}
//                     className="fixed top-0 left-0 right-0 z-50 bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg shadow-sm border-b border-gray-200/50 dark:border-gray-700/50"
//                     style={{ top: '56px' }}
//                 >
//                     <div className="container mx-auto px-4 pt-4 pb-3">
//                         <div className="flex items-center gap-4">
//                             <div className="relative flex-1">
//                                 <div className="relative">
//                                     <input
//                                         type="text"
//                                         value={query}
//                                         onChange={(e) => setQuery(e.target.value)}
//                                         placeholder={placeholderText}
//                                         onMouseEnter={() => handleInputHover(true)}
//                                         onMouseLeave={() => handleInputHover(false)}
//                                         className="w-full pl-12 pr-4 py-3 rounded-full border border-gray-200 dark:border-gray-700
//                                         bg-white/70 dark:bg-gray-800/70 backdrop-blur-md shadow-lg
//                                         focus:outline-none focus:ring-2 focus:ring-[#f7bb17]
//                                         placeholder-gray-400 dark:placeholder-gray-500 text-gray-900 dark:text-white
//                                         transition-all duration-300"
//                                     />
//                                     <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" size={20} />
//                                 </div>
//                             </div>

//                             <div className="flex gap-2">
//                                 <motion.button
//                                     whileHover={{ scale: 1.05 }}
//                                     whileTap={{ scale: 0.95 }}
//                                     onClick={() => setActiveTab('companies')}
//                                     className={`p-3 rounded-full transition-all duration-300 ${activeTab === 'companies'
//                                         ? 'bg-[#f7bb17] text-white shadow-lg'
//                                         : 'bg-white/70 dark:bg-gray-800/70 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
//                                         }`}
//                                 >
//                                     <Building2 size={20} />
//                                 </motion.button>

//                                 <motion.button
//                                     whileHover={{ scale: 1.05 }}
//                                     whileTap={{ scale: 0.95 }}
//                                     onClick={() => setActiveTab('products')}
//                                     className={`p-3 rounded-full transition-all duration-300 ${activeTab === 'products'
//                                         ? 'bg-[#f7bb17] text-white shadow-lg'
//                                         : 'bg-white/70 dark:bg-gray-800/70 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
//                                         }`}
//                                 >
//                                     <Package size={20} />
//                                 </motion.button>
//                             </div>
//                         </div>

//                         <div className="mt-3">
//                             <div className="flex gap-2 overflow-x-auto scrollbar-hide">
//                                 {categories.map(category => (
//                                     <motion.button
//                                         key={category.id}
//                                         whileHover={{ scale: 1.05 }}
//                                         whileTap={{ scale: 0.95 }}
//                                         onClick={() => handleCategoryToggle(category.id)}
//                                         className={`
//                                 py-1 px-4 rounded-full whitespace-nowrap text-sm
//                                 ${selectedCategories.includes(category.id)
//                                                 ? 'bg-[#f7bb17] text-white shadow-md'
//                                                 : 'bg-white/70 dark:bg-gray-800/70 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
//                                             }
//                                 border border-gray-200 dark:border-gray-700 backdrop-blur-sm transition-all duration-300
//                               `}
//                                     >
//                                         {category.name}
//                                     </motion.button>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>

//                     <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white/80 dark:from-gray-900/80 to-transparent pointer-events-none" />
//                 </motion.div>
//             )}
//         </AnimatePresence>
//     )
// }