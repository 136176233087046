import {useEffect, useState} from "react";
import { QRCodeSVG } from 'qrcode.react';
import {
    Avatar,
    Badge,
    Box,
    Center,
    Container,
    Heading,
    Image,
    Spinner,
    Text,
    useColorModeValue,
    useToast,
    VStack
} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { config as reactConfig } from '../../config/config.js';

const ProfileCard = () => {
    const [token, setToken] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toast = useToast();
    const navigate = useNavigate();

    const fetchUserProfile = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${reactConfig.backend.baseUrl}/users/me`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUserProfile(response.data.userProfile);
        } catch (error) {
            console.error('Error fetching digital card: ', error);
            setError('Error cargando tarjeta.');
            toast({
                title: 'Error cargando tarjeta',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true
            })
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("User not authenticated.");
            toast({
                title: 'Error de autenticación',
                description: 'Usuario no autenticado.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        } else {
            setToken(token);
        }
    }, [navigate]);

    useEffect(() => {
        if (token) {
            fetchUserProfile();
        }
    }, [token]);

    const bgGradient = useColorModeValue("linear(to-r, #B22224, #8B1618)", "linear(to-r, #B22224, #8B1618)");
    const cardBg = useColorModeValue("white", "#1f2937");
    const textColor = useColorModeValue("gray.700", "white");
    const subTextColor = useColorModeValue("gray.600", "gray.400");
    const avatarBg = useColorModeValue("#F4D6D6", "#3D1111");
    const avatarColor = useColorModeValue("#B22224", "#F4D6D6");


    if (loading) return (
        <Center minH="100vh">
            <Spinner size="xl" color="#B22224" />
            <Text ml={4}>Cargando...</Text>
        </Center>
    );

    if (error && error.length > 0) return (
        <Center minH="100vh">
            <Text color="red.500">{error}</Text>
        </Center>
    );

    if (!userProfile) return (
        <Center minH="100vh">
            <Text color="red.500">Perfil no encontrado</Text>
        </Center>
    );

    return (
        <Box minH="100vh"  py={6} transition="colors duration-200">
            <Container centerContent py={12}>
                <Box position="relative" maxW="xl" w="full">
                    {/* Background gradient */}
                    <Box
                        position="absolute"
                        maxW="440px"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bgGradient={bgGradient}
                        shadow="2xl"
                        transform="rotate(-6deg)"
                        borderRadius="xl"
                        zIndex={0}
                    />

                    {/* Card content */}
                    <Box
                        position="relative"
                        maxW="440px"
                        bg={cardBg}
                        shadow="2xl"
                        borderRadius="xl"
                        p={{ base: 4, md: 10 }}
                        zIndex={1}
                    >
                        <VStack spacing={4} align="center" maxW="md" mx="auto">
                            {userProfile.profilePhoto ? (
                                <Image
                                    src={userProfile.profilePhoto.url}
                                    alt="Profile"
                                    boxSize="128px"
                                    borderRadius="full"
                                    objectFit="cover"
                                />
                            ) : (
                                <Avatar
                                    size="2xl"
                                    name={userProfile.fullName}
                                    bg={avatarBg}
                                    color={avatarColor}
                                />
                            )}

                            <Heading as="h2" size="lg" textAlign="center" color={textColor}>
                                {userProfile.fullName}
                            </Heading>

                            <VStack spacing={1} textAlign="center">
                                <Text color={subTextColor}>{userProfile.email}</Text>
                                <Text color={subTextColor}>{userProfile.phoneNumber}</Text>
                            </VStack>

                            <Badge
                                borderRadius="full"
                                px={4}
                                py={1}
                                colorScheme={userProfile.digitalCard?.isActive ? 'green' : 'red'}
                            >
                                {userProfile.digitalCard?.isActive ? 'Activo' : 'Inactivo'}
                            </Badge>

                            <Box mt={4}>
                                <QRCodeSVG
                                    value={userProfile.digitalCard?.cardId || ""}
                                    size={200}
                                    level="H"
                                    includeMargin={true}
                                    fgColor="#000000"
                                />

                                <Text
                                    fontSize="2xs"
                                    color={subTextColor}
                                    wordBreak="break-all"
                                    textAlign="center"
                                    _hover={{ color: "#B22224" }}
                                >
                                    ID: {userProfile.digitalCard?.cardId}
                                </Text>
                            </Box>
                        </VStack>
                    </Box>
                </Box>
            </Container>

            <style jsx>{`
                .dark .span {
                    color: #B22224;
                }

                .button-submit {
                    background-color: #B22224;
                }

                .dark .button-submit:hover {
                    background-color: #8B1618;
                }

                .dark .inputForm:focus-within {
                    border: 1.5px solid #B22224;
                }

                .dark {
                    background-color: #1f2937;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
                }
            `}</style>
        </Box>
    );
};

export default ProfileCard;