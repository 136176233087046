import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text} from '@chakra-ui/react';
import Header from './components/Header';
import Login from './components/Login';
import Register from './components/Register';
import MenuBar from './components/MenuBar';
import Profile from './components/Profile';
import Settings from './components/Settings';
import CompanyCategory from './components/CompanyCategory';
import AsobaresChatbot from './components/Chatbot/ChatbotAsobares';
import BarList from './components/Commerces/BarList';
import CompanyDetail from './components/Commerces/CompanyDetail';
import InstallPrompt from "./InstallPrompt";

// Theme configuration similar to the session expiration handler
const theme = {
  colors: {
    primary: '#0F172A',      // Midnight Blue
    secondary: '#B22224',    // Satin Red
    background: '#1C1C1E',   // Anthracite Gray
    text: '#E5E7EB',         // Smoke White
  }
};

// Session Expiration Modal Component
const SessionExpirationModal = ({ isOpen, onClose }) => {
  return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
            bg={theme.colors.primary}
            color={theme.colors.text}
            borderRadius="lg"
        >
          <ModalHeader
              borderBottomWidth="1px"
              borderColor="gray.700"
              color={theme.colors.secondary}
          >
            Sesión Expirada
          </ModalHeader>
          <ModalBody py={6}>
            <Text color={theme.colors.text}>
              Tu sesión ha expirado. Por favor, inicia sesión nuevamente para continuar.
            </Text>
          </ModalBody>
          <ModalFooter
              borderTopWidth="1px"
              borderColor="gray.700"
          >
            <Button
                onClick={onClose}
                bg={theme.colors.secondary}
                color={theme.colors.primary}
                _hover={{ opacity: 0.9 }}
            >
              Iniciar Sesión
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  );
};

// Token Verification Wrapper Component
const TokenVerifier = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear local storage
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // Remove Authorization  header
    delete axios.defaults.headers.common['Authorization'];
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      // Decode the token to get expiration time
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        handleLogout();
        setIsModalOpen(true);
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error checking token expiration:', error);
      handleLogout();
      setIsModalOpen(true);
      return false;
    }
  };

  useEffect(() => {
    // Check token expiration immediately
    checkTokenExpiration();

    // Set up interval to check token expiration periodically
    const intervalId = setInterval(checkTokenExpiration, 5 * 60 * 1000); // Check every 5 minutes

    // Intercept axios responses to catch unauthorized errors
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            handleLogout();
            setIsModalOpen(true);
          }
          return Promise.reject(error);
        }
    );

    // Cleanup function
    return () => {
      clearInterval(intervalId);
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/login');
  };

  return (
      <>
        {children}
        <SessionExpirationModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
        />
      </>
  );
};

// Componente envoltorio para manejar la lógica de visualización
const AppContent = ({ darkMode, toggleDarkMode }) => {
  const location = useLocation();
  const isChatbotRoute = location.pathname.startsWith('/chatbot/');

  return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
        {!isChatbotRoute && <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}
        <main className={`container mx-auto ${!isChatbotRoute ? 'pt-16 pb-14' : ''} text-gray-900 dark:text-white`}>
          <Routes>
            <Route path="/" element={<BarList />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/company-categories/:categoryId" element={<CompanyCategory />} />
            <Route path="/chatbot" element={<AsobaresChatbot />} />
            <Route path="/commerces" element={<BarList />} />
            <Route path="/commerces/:companyId" element={<CompanyDetail />} />
          </Routes>
        </main>
        {!isChatbotRoute && <MenuBar />}
      </div>
  );
};

function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    if (newDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  return (
      <>
        <InstallPrompt />
        <Router>
          <TokenVerifier>
            <AppContent darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          </TokenVerifier>
        </Router>
      </>
  );
}

export default App;