import React, {useEffect, useState} from 'react';
import {
    Box,
    Flex,
    Input,
    Button,
    Text,
    Image,
    IconButton,
    Avatar,
    Badge,
    useColorModeValue,
    useToast, Spinner
} from '@chakra-ui/react';
import {
    FaArrowLeft,
    FaPaperPlane, FaRedoAlt,
    FaUserCircle
} from 'react-icons/fa';
import logo from '../../assets/logo.png';
import axios from 'axios';

const AsobaresChatbot = () => {
    const toast = useToast();
    const api = process.env.REACT_APP_BACKEND_BASE_URL
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [loading, setLoading] = useState(false);
    const conversationKey = "conversation";

    useEffect(() => {
        const storedConversation = sessionStorage.getItem(conversationKey);
        if (storedConversation) {
            setChat(JSON.parse(storedConversation));
        } else {
            const initialMessage = [
                { role: 'system', content: '¡Hola! ¿En qué puedo ayudarte hoy?' }
            ];
            setChat(initialMessage);
            sessionStorage.setItem(conversationKey, JSON.stringify(initialMessage));
        }
        const handleBeforeUnload = () => {
            sessionStorage.removeItem(conversationKey);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const saveConversation = (conversation) => {
        sessionStorage.setItem(conversationKey, JSON.stringify(conversation));
    }

    const postConversation = async (currentConversation) => {
        setLoading(true);
        try {
            const response = await axios.post(`${api}/chatbots/1/chat`, {
                conversation: currentConversation
            });
            return response.data;
        } finally {
            setLoading(false);
        }
    }

    const sendMessage = async () => {
        if (message.trim() === '') return;

        let conversation = [...chat];
        if (conversation.length > 0 && conversation[conversation.length - 1].error) {
            conversation = conversation.slice(0, conversation.length - 1);
        }

        const userMessage = { role: 'user', content: message };
        conversation = [...conversation, userMessage];

        setChat(conversation);
        saveConversation(conversation);
        setMessage('');

        try {
            const botMessage = await postConversation(conversation);
            const newConversation = [...conversation, botMessage];
            setChat(newConversation);
            saveConversation(newConversation);
        } catch (error) {
            console.error('Error sending message:', error);
            toast({
                title: 'Error',
                description: 'Ha ocurrido un error al enviar el mensaje.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
            const failedConversation = conversation.map((msg, index) =>
                index === conversation.length -1 ? {...msg, error: true} : msg
            );
            setChat(failedConversation);
            saveConversation(failedConversation);
        }
    };

    const handleRetry = async () => {
        const conversation = [...chat];
        const updatedConversation = conversation.map((msg, index) =>
            index === conversation.length -1 ? {...msg, error: false} : msg
        );
        setChat(updatedConversation);
        saveConversation(updatedConversation);
        try {
            const botMessage = await postConversation(updatedConversation);
            const newConversation = [...updatedConversation, botMessage];
            setChat(newConversation);
            saveConversation(newConversation);
        } catch (error) {
            console.error('Error retrying message:', error);
            toast({
                title: 'Error',
                description: 'Ha ocurrido un error al reintentar el mensaje.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
            const failedConversation = updatedConversation.map((msg, index) =>
                index === updatedConversation.length - 1 ? { ...msg, error: true } : msg
            );
            setChat(failedConversation);
            saveConversation(failedConversation);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    return (
        <Flex
            direction="column"
            h="80vh"
            w={"full"}
            bg="gray.900"
            color="white"
        >
            {/* Header */}
            <Flex
                align="center"
                justify="space-between"
                p={4}
                bg="gray.800"
            >
                <Flex align="center" gap={2}>
                    <IconButton
                        icon={<FaArrowLeft />}
                        variant="ghost"
                        color="gray.400"
                        _hover={{ color: "white" }}
                        aria-label="Go back"
                    />
                    <Image
                        src={logo}
                        alt="Asobares logo"
                        boxSize="8"
                    />
                    <Box>
                        <Text fontWeight="semibold" fontSize="lg">
                            Asobares Chatbot
                        </Text>
                        <Text fontSize="sm" color="gray.400">
                            Online
                        </Text>
                    </Box>
                </Flex>
            </Flex>

            {/* Chat Area */}
            <Box
                flex="1"
                p={4}
                overflowY="auto"
                display="flex"
                flexDirection="column"
                gap="4"
            >
                {chat.map((msg, index) => (
                    <Box key={index}>
                        {msg.role === 'user' ? (
                            <Flex direction="column" align="flex-end">
                                <Flex align="center" gap={2}>
                                    <Badge bg="red.600" color="white" px={3} py={1} borderRadius="full">
                                        {msg.content}
                                    </Badge>
                                    <Box as={FaUserCircle} fontSize="2xl" color="red.600" />
                                </Flex>
                                {msg.error && (
                                    <Flex
                                        align="center"
                                        mt={1}
                                        cursor="pointer"
                                        onClick={handleRetry}
                                        color="red.400"
                                    >
                                        <Text fontSize="xs" mr={1}>
                                            Algo ha salido mal, reintente de nuevo más tarde
                                        </Text>
                                        <Box as={FaRedoAlt} />
                                    </Flex>
                                )}
                            </Flex>
                        ) : (
                            <Flex justify="flex-start">
                                <Box bg="gray.700" px={4} py={2} borderRadius="lg" maxW="xs">
                                    {msg.content}
                                </Box>
                            </Flex>
                        )}
                    </Box>
                ))}

                {/* Spinner de loading para la respuesta del assistant */}
                {loading && (
                    <Flex justify="flex-start" align="center" mt={2}>
                        <Box
                            bg="gray.700"
                            px={4}
                            py={2}
                            borderRadius="lg"
                            maxW="xs"
                            display="flex"
                            alignItems="center"
                        >
                            <Spinner size="md" mr={2} color="white" />
                        </Box>
                    </Flex>
                )}
            </Box>

            {/* Message Input */}
            <Flex p={4} bg="gray.800" align="center">
                <Input
                    flex="1"
                    bg="gray.700"
                    color="white"
                    px={4}
                    py={2}
                    borderRadius="lg"
                    borderRightRadius="0"
                    placeholder="Escribe tu mensaje..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    _focus={{ outline: "none" }}
                    disabled={loading}
                />
                <Button
                    bg="red.600"
                    color="white"
                    px={4}
                    py={2}
                    borderRadius="lg"
                    borderLeftRadius="0"
                    onClick={sendMessage}
                >
                    <FaPaperPlane />
                </Button>
            </Flex>
        </Flex>
    );
};

export default AsobaresChatbot;