import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Container,
    Flex,
    Text,
    Heading,
    Badge,
    Image,
    Grid,
    GridItem,
    Stack,
    HStack,
    VStack,
    Divider,
    useMediaQuery,
    Skeleton,
    Button,
    Icon,
    Tag,
    TagLabel,
    TagLeftIcon,
    useToast,
} from '@chakra-ui/react';
import {
    FaWhatsapp,
    FaFacebook,
    FaInstagram,
    FaGlobe,
    FaClock,
    FaMapMarkerAlt,
    FaTag,
    FaImage,
    FaShare,
    FaHeart,
    FaCalendarAlt
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

// Configuración de tema personalizado para ChakraUI
const customTheme = {
    colors: {
        primary: '#0F172A',      // Azul Medianoche
        secondary: '#B22224',    // Rojo Satinado
        background: '#1C1C1E',   // Gris Antracita
        text: '#E5E7EB',         // Blanco Humo
        success: '#16A34A',      // Verde Esmeralda
        warning: '#FF8C00',      // Ámbar Intenso
        error: '#B91C1C',        // Rojo Granate
        cardBg: '#252529',       // Un poco más claro que el fondo para las cards
        inputBg: '#2C2C30',      // Fondo para inputs
    }
};

// Componente para mostrar el horario de negocio
const BusinessHours = ({ businessHours }) => {
    const days = {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo'
    };

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    return (
        <Box
            w="100%"
            maxW="100%" // Asegura que no exceda el ancho del contenedor padre
            bg={customTheme.colors.cardBg}
            p={4}
            borderRadius="md"
            boxShadow="md"
            overflow="hidden" // Previene desbordamiento
            transition="transform 0.3s ease-in-out"
            _hover={{ transform: 'translateY(-5px)' }}
        >
            <HStack mb={4}>
                <Icon as={FaClock} color={customTheme.colors.secondary} boxSize={5} />
                <Heading size="md" color={customTheme.colors.text}>Horario de Atención</Heading>
            </HStack>
            <Divider mb={4} borderColor={`${customTheme.colors.primary}50`} />
            {isLargerThan768 ? (
                <Grid templateColumns="repeat(7, 1fr)" gap={2} fontSize="sm" overflowX="auto">
                    {Object.entries(days).map(([dayKey, dayName]) => (
                        <Box
                            key={dayKey}
                            bg={`${customTheme.colors.primary}80`}
                            p={2}
                            borderRadius="md"
                            textAlign="center"
                            minW="70px" // Ancho mínimo para evitar compresión excesiva
                        >
                            <Text fontWeight="bold" color={customTheme.colors.text} fontSize="xs">
                                {dayName.substring(0, 3)} {/* Abreviamos el nombre del día */}
                            </Text>
                            {businessHours[dayKey] && !businessHours[dayKey].isClosed ? (
                                <Text color={customTheme.colors.text} fontSize="xs">
                                    {businessHours[dayKey].open} - {businessHours[dayKey].close}
                                </Text>
                            ) : (
                                <Text color={customTheme.colors.error}>Cerrado</Text>
                            )}
                        </Box>
                    ))}
                </Grid>
            ) : (
                <VStack spacing={2} align="stretch">
                    {Object.entries(days).map(([dayKey, dayName]) => (
                        <HStack
                            key={dayKey}
                            justifyContent="space-between"
                            bg={`${customTheme.colors.primary}50`}
                            p={2}
                            borderRadius="md"
                        >
                            <Text fontWeight="bold" color={customTheme.colors.text}>{dayName}</Text>
                            {businessHours[dayKey] ? (
                                <Text color={customTheme.colors.text} fontSize="xs">
                                    {businessHours[dayKey].open} - {businessHours[dayKey].close}
                                </Text>
                            ) : (
                                <Text color={customTheme.colors.error}>Cerrado</Text>
                            )}
                        </HStack>
                    ))}
                </VStack>
            )}
        </Box>
    );
};

// Componente de galería de imágenes
const Gallery = ({ photos, coverPhoto }) => {
    const [selectedImage, setSelectedImage] = useState(coverPhoto?.url || '');
    const allPhotos = [coverPhoto, ...photos].filter(Boolean);

    return (
        <Box width="100%" maxW="100%" overflow="hidden"> {/* Forzar limites y prevenir desbordamiento */}
            <Box
                position="relative"
                w="100%"
                maxW="100%" // Asegura contención dentro del padre
                // height={{ base: "200px", md: "300px", lg: "400px" }}
                height={"100%"}
                mb={4}
                borderRadius="md"
                overflow="hidden"
                boxShadow="lg"
            >
                <Image
                    src={selectedImage}
                    alt="Selected photo"
                    objectFit="cover"
                    w="100%"
                    h="100%"
                    fallback={<Skeleton height="100%" width="100%" />}
                />
            </Box>
            <Box w="100%" maxW="100%" overflow="hidden"> {/* Contenedor adicional para asegurar contención */}
                <Flex
                    w="100%"
                    overflowX="auto" // Scroll horizontal si es necesario
                    py={2}
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: customTheme.colors.primary,
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: customTheme.colors.secondary,
                            borderRadius: '8px',
                        },
                    }}
                >
                    {allPhotos.map((photo, index) => (
                        <Box
                            key={index}
                            w={{ base: "60px", md: "100px" }} // Reducido para móviles
                            h={{ base: "45px", md: "70px" }}   // Reducido proporcionalmente
                            flexShrink={0}
                            mr={2}
                            borderRadius="md"
                            overflow="hidden"
                            border={selectedImage === photo.url ? `2px solid ${customTheme.colors.secondary}` : "none"}
                            cursor="pointer"
                            onClick={() => setSelectedImage(photo.url)}
                            transition="transform 0.2s"
                            _hover={{ transform: 'scale(1.05)' }}
                        >
                            <Image
                                src={photo.url}
                                alt={`Gallery photo ${index}`}
                                objectFit="cover"
                                h="100%"
                                w="100%"
                                fallback={<Skeleton height="100%" width="100%" />}
                            />
                        </Box>
                    ))}
                </Flex>
            </Box>
        </Box>
    );
};

// Componente de información social y contacto
const SocialInfo = ({ whatsapp, facebook, instagram, website }) => {
    const toast = useToast();

    const handleSocialClick = (type, link) => {
        if (!link) {
            toast({
                title: "Información no disponible",
                description: `Este comercio no ha proporcionado su ${type}`,
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }
        window.open(link, '_blank');
    };

    return (
        <Box
            w="100%"
            maxW="100%" // Asegura que no exceda el ancho del contenedor padre
            bg={customTheme.colors.cardBg}
            p={4}
            borderRadius="md"
            boxShadow="md"
            mt={4}
            overflow="hidden" // Previene desbordamiento
        >
            <HStack mb={4}>
                <Icon as={FaShare} color={customTheme.colors.secondary} boxSize={5} />
                <Heading size="md" color={customTheme.colors.text}>Contacto y Redes</Heading>
            </HStack>
            <Divider mb={4} borderColor={`${customTheme.colors.primary}50`} />
            <Box w="100%" maxW="100%"> {/* Contenedor adicional para control */}
                <Flex
                    justify="center"
                    flexWrap="wrap"
                    gap={2}
                    w="100%"
                >
                    <Button
                        leftIcon={<FaWhatsapp />}
                        colorScheme="green"
                        variant="solid"
                        size={{ base: "md", md: "md" }} // Reducido para móviles
                        bg={whatsapp ? customTheme.colors.success : `${customTheme.colors.inputBg}`}
                        opacity={whatsapp ? 1 : 0.6}
                        onClick={() => handleSocialClick('WhatsApp', whatsapp)}
                        flexBasis={{ base: "45%", md: "auto" }} // Control de ancho base
                        m={1}
                    >
                        WhatsApp
                    </Button>
                    <Button
                        leftIcon={<FaFacebook />}
                        colorScheme="facebook"
                        variant="solid"
                        size={{ base: "md", md: "md" }} // Reducido para móviles
                        bg={facebook ? "#4267B2" : `${customTheme.colors.inputBg}`}
                        opacity={facebook ? 1 : 0.6}
                        onClick={() => handleSocialClick('Facebook', facebook)}
                        flexBasis={{ base: "45%", md: "auto" }} // Control de ancho base
                        m={1}
                    >
                        Facebook
                    </Button>
                    <Button
                        leftIcon={<FaInstagram />}
                        colorScheme="pink"
                        variant="solid"
                        size={{ base: "md", md: "md" }} // Reducido para móviles
                        bgGradient={instagram ? "linear(to-r, #8a3ab9, #e95950, #bc2a8d)" : "none"}
                        bg={instagram ? "" : `${customTheme.colors.inputBg}`}
                        opacity={instagram ? 1 : 0.6}
                        onClick={() => handleSocialClick('Instagram', instagram)}
                        flexBasis={{ base: "45%", md: "auto" }} // Control de ancho base
                        m={1}
                    >
                        Instagram
                    </Button>
                    <Button
                        leftIcon={<FaGlobe />}
                        colorScheme="blue"
                        variant="solid"
                        size={{ base: "md", md: "md" }} // Reducido para móviles
                        bg={website ? customTheme.colors.primary : `${customTheme.colors.inputBg}`}
                        opacity={website ? 1 : 0.6}
                        onClick={() => handleSocialClick('sitio web', website)}
                        flexBasis={{ base: "45%", md: "auto" }} // Control de ancho base
                        m={1}
                    >
                        Web
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

// Componente principal de detalles del comercio
const CompanyDetail = () => {
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const toast = useToast();
    const api = process.env.REACT_APP_BACKEND_BASE_URL;

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                setLoading(true);
                const response = await axios.get(api + `/companies/${companyId}`);
                setCompany(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching company details:', err);
                setError('No pudimos cargar la información del comercio. Por favor, intenta nuevamente más tarde.');
                setLoading(false);
                toast({
                    title: "Error",
                    description: "No pudimos cargar la información del comercio",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchCompany();
    }, [companyId, toast, api]);

    const MotionBox = motion.create(Box);

    if (loading) {
        return (
            <Container maxW="container.xl" p={{ base: 4, md: 8 }} bg={customTheme.colors.background}>
                <Stack spacing={6}>
                    <Skeleton height="300px" />
                    <Skeleton height="100px" />
                    <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={6}>
                        <Skeleton height="400px" />
                        <Stack>
                            <Skeleton height="200px" />
                            <Skeleton height="150px" mt={4} />
                        </Stack>
                    </Grid>
                </Stack>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxW="container.xl" p={8} bg={customTheme.colors.background}>
                <Box textAlign="center" py={10} px={6}>
                    <Heading as="h2" size="xl" color={customTheme.colors.error} mb={4}>
                        Error
                    </Heading>
                    <Text color={customTheme.colors.text}>{error}</Text>
                    <Button
                        colorScheme="red"
                        bg={customTheme.colors.secondary}
                        mt={6}
                        onClick={() => window.location.reload()}
                    >
                        Reintentar
                    </Button>
                </Box>
            </Container>
        );
    }

    if (!company) return null;

    return (
        <Box
            bg={customTheme.colors.background}
            minH="100vh"
            w="100%"
            overflowX="hidden" // Previene desbordamiento horizontal
        >
            <Container
                maxW="container.xl"
                p={{ base: 4, md: 8 }}
                overflow="hidden" // Asegura que el contenido no desborde
            >
                {/* Header con imagen de portada */}
                <MotionBox
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    position="relative"
                    height={{ base: "150px", md: "250px" }}
                    mb={6}
                    borderRadius="xl"
                    overflow="hidden"
                    boxShadow="xl"
                >
                    <Image
                        src={company.coverPhoto?.url}
                        alt={`${company.name} cover`}
                        objectFit="cover"
                        w="100%"
                        h="100%"
                        fallback={<Skeleton height="100%" width="100%" />}
                    />
                    {company.discount && (
                        <Badge
                            position="absolute"
                            top={4}
                            right={4}
                            bg={customTheme.colors.secondary}
                            color={customTheme.colors.text}
                            py={2}
                            px={3}
                            borderRadius="md"
                            fontSize={{ base: "xs", md: "md" }}
                            fontWeight="bold"
                            boxShadow="md"
                        >
                            Descuento: {company.discount}
                        </Badge>
                    )}
                </MotionBox>

                {/* Información principal */}
                <MotionBox
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    mb={8}
                    maxW="100%" // Límite explícito
                    overflow="hidden" // Previene desbordamiento
                >
                    <Flex
                        direction={{ base: "column", md: "row" }}
                        align={{ base: "start", md: "center" }}
                        justify="space-between"
                        mb={4}
                        wrap="wrap" // Permite wrapping si es necesario
                    >
                        <Box maxW="100%">
                            <Heading
                                color={customTheme.colors.text}
                                size={{ base: "xl", md: "2xl" }}
                                mb={2}
                                isTruncated // Trunca el texto si es muy largo
                            >
                                {company.name}
                            </Heading>
                            <Flex
                                wrap="wrap"
                                gap={2}
                                mb={3}
                            >
                                <Tag size="sm" variant="subtle" colorScheme="blue" bg={customTheme.colors.primary}>
                                    <TagLeftIcon as={FaTag} boxSize={3} />
                                    <TagLabel fontSize="xs">{company.category?.name}</TagLabel>
                                </Tag>
                                <Tag size="sm" variant="subtle" colorScheme="orange" bg={`${customTheme.colors.warning}30`}>
                                    <TagLeftIcon as={FaMapMarkerAlt} boxSize={3} />
                                    <TagLabel fontSize="xs">{company.country}</TagLabel>
                                </Tag>
                                <Tag size="sm" variant="subtle" colorScheme="green" bg={`${customTheme.colors.success}30`}>
                                    <TagLeftIcon as={FaCalendarAlt} boxSize={3} />
                                    <TagLabel fontSize="xs">
                                        {new Date(company.createdAt).toLocaleDateString('es-ES', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric'
                                        })}
                                    </TagLabel>
                                </Tag>
                            </Flex>
                        </Box>
                        <Flex gap={2} mt={{ base: 4, md: 0 }} wrap="wrap">
                            <Button
                                leftIcon={<FaHeart />}
                                colorScheme="red"
                                variant="outline"
                                size={{ base: "xs", md: "md" }}
                                bg="transparent"
                                border={`1px solid ${customTheme.colors.secondary}`}
                                color={customTheme.colors.secondary}
                                _hover={{ bg: `${customTheme.colors.secondary}20` }}
                            >
                                Favorito
                            </Button>
                            <Button
                                leftIcon={<FaShare />}
                                colorScheme="blue"
                                variant="solid"
                                size={{ base: "xs", md: "md" }}
                                bg={customTheme.colors.primary}
                                _hover={{ bg: `${customTheme.colors.primary}90` }}
                            >
                                Compartir
                            </Button>
                        </Flex>
                    </Flex>
                    <Text
                        color={customTheme.colors.text}
                        fontSize={{ base: "md", md: "lg" }}
                        lineHeight="tall"
                        noOfLines={{ base: 3, md: 0 }} // Limita a 3 líneas en móvil, expandible con botón
                    >
                        {company.description}
                    </Text>
                </MotionBox>

                {/* Contenido principal */}
                <Grid
                    templateColumns={{ base: "1fr", lg: "3fr 2fr" }}
                    gap={{ base: 4, md: 8 }}
                >
                    {/* Columna izquierda - Galería */}
                    <MotionBox
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        maxW="100%" // Límite explícito
                        overflow="hidden" // Previene desbordamiento
                    >
                        <Heading
                            size={{ base: "md", md: "lg" }}
                            mb={4}
                            color={customTheme.colors.text}
                            display="flex"
                            alignItems="center"
                        >
                            <Icon as={FaImage} mr={2} color={customTheme.colors.secondary} />
                            Galería
                        </Heading>
                        <Gallery
                            photos={company.photos || []}
                            coverPhoto={company.coverPhoto}
                        />
                    </MotionBox>

                    {/* Columna derecha - Información adicional */}
                    <MotionBox
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                        maxW="100%" // Límite explícito
                        overflow="hidden" // Previene desbordamiento
                    >
                        <Stack spacing={{ base: 4, md: 6 }}>
                            {/* Horarios */}
                            <BusinessHours businessHours={company.businessHours || {}} />

                            {/* Redes sociales y contacto */}
                            <SocialInfo
                                whatsapp={company.whatsapp}
                                facebook={company.facebook}
                                instagram={company.instagram}
                                website={company.website}
                            />
                        </Stack>
                    </MotionBox>
                </Grid>
            </Container>
        </Box>
    );
};

export default CompanyDetail;